/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

export default function Home() {
    const [items,  setItems] = useState([]);

    const responsive = {
        400: { items: 1 },
        668: { items: 2 },
        1024: { items: 3 },
        1500: { items: 5 }
    };

    const getReq = async () => {
        setItems([]);
        let query =  await axios.get(`https://billsdev.space:7500/projects/all`); // 138.197.151.61

        const constructItem = (_name, _image, _demo, _repository, _description) => {
            items.push(
                <Link to={'/project-single'} state={{ name: `${_name}`, image: `${_image}`, demo: `${_demo}`, repository: `${_repository}`, description: `${_description}` }}>
                    <div className='grid place-items-center'>
                        <img src={`${_image}`} alt='' className='carouselImage'></img>
                        <p className='item_title'>{_name}</p>
                    </div>
                </Link>
            );

            setItems(items.reverse());
        }

        for(let i = 0; i < query.data.length; i++){  
            constructItem(
                query.data[i].title, 
                query.data[i].image, 
                query.data[i].demo,
                query.data[i].repository, 
                query.data[i].description, 
                );         
        }
    }

    useEffect(() => {
        getReq() 

        const [red, green, blue] = [13, 209, 206]
        const section1 = document.querySelector('.projects')
    
        window.addEventListener('scroll', () => {
        const y = 1 + (window.scrollY) / 150
        const [r, g, b] = [red/y, green/y, blue/y].map(Math.round)
        section1.style.backgroundColor = `rgb(${r}, ${g}, ${b})`
        })
    }, []);
 

    return (
        <>
        <div id='background-home'>

            <div id='content'>
                {/* <button onClick={constructItem}>test</button> */}
               <div className='HomeHeader'>
                    <h3 id='home-title'>Billy Best</h3>
                    <p id='headline'> A full stack developer.</p>
                    <p id='tagline'>I do native software, mobile applications & web.</p>    
                   
                    <Link to='/contact'><button  id='navbtn' className='border border-cyan-600 p-2'>contact</button></Link>
               </div>

                <div className='projects' >
                    <Link to='/work'>
                    <h4 id='projects-title'>Work</h4>   
                    </Link>
                    
                    <p id=''>Projects I built or participated on</p>     
                    <AliceCarousel
                        mouseTracking
                        items={items}
                        responsive={responsive}
                        autoPlayInterval={3500}
                        autoPlayDirection="ltr"
                        autoPlay={true}
                        infinite={true}
                        mouseTrackingEnabled={true}
                    />
                </div>
                


              

            </div>
            <a href='#' class="top"> &#8593;</a>
            <div id='bottom-spacer'></div>

            <div class="footer_svg">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                </svg>
            </div>

        </div>
        
        <style>{`
          @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&family=Roboto+Mono:wght@100&display=swap');
            #background-home {
                width: auto;
                height: auto;
                background: rgb(0,19,9);
                background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,40,80,1) 100%);
                padding-top: 100px;
                padding-bottom: 1px;
            }

            .HomeHeader {
                margin-bottom: 15%;
            }
            
            #content {
                width: 100%;
                height: 100%;  
            }
    
            #headline {
                padding-top: 20px;
                color: #fbff12;
                font-size: 30px;
                text-shadow: -1px -1px 0 #000000, 2px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000;
            }

            #tagline {
                font-size: 16px;
                font-family: 'Roboto Mono', monospace;
                color: #00ff6e;
                padding-bottom: 10px;
               
            }

            #landing-cta { 
                background-color: #2e2e28;
                width: 500px;
                opacity: 0.75;
                line-height: 32px;
                border-radius: 10px 100px / 120px;
                outline-style: solid;
                margin-top:10px;

            }
            
            #home-title {
                text-transform: uppercase;
                background-image: linear-gradient(
                    -225deg,
                    #231557 0%,
                    #44107a 29%,
                    #ff1361 67%,
                    #fff800 100%
                );
                background-size: auto auto;
                background-clip: border-box;
                background-size: 200% auto;
                color: #fff;
                background-clip: text;
                text-fill-color: transparent;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                animation: textclip 3s linear infinite;
                display: inline-block;
                font-size: 92px;
                font-family: 'Roboto Mono', monospace;
                margin: 0px;
                padding-top: 8%;
                padding-bottom: 3%;
              
            }

            @keyframes textclip {
                to {
                    background-position: 200% center;
                }
            }

            .center-content {
                display: flex;
                justify-content: center;  
               
            }
          
            .projects {
                background: rgb(0, 0, 0);
                border: 1px solid crimson;
                margin: 12px;
                color: white;
                filter: drop-shadow(0 0 1.75rem cyan);
            }

            #projects-title {
                padding-top: 12px;
                font-size: 36px;
                font-family: 'Roboto Mono', monospace;
                font-weight: bold;
                color: white;
                text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
            }

            .carouselImage {
                height: 290px;
                width: 290px;
            }

            .alice-carousel__wrapper {  
                margin-left: 0.2%;              
            }

            .alice-carousel__next-btn-item {  
               padding-right: 99%;
            }

            li.alice-carousel__stage-item {     
            }
  
            .item_title {
                background-color: black;
                text-shadow: -1px -1px 0 #000000, 2px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000;
                font-size: 20px;
                position: relative;
                bottom: 0;
                text-align: center;
                width: 80%;
                border: 1px solid crimson;
                margin-left: auto;
                margin-right: auto;
                margin-top: -16px;
                padding: 3px;
            }

            .top {
                --offset: 50px; 
                margin-left: 90%;
                position: sticky;
                bottom: 20px;      
                margin-right:10px;  
                margin-top: calc(145vh + var(--offset));
                
                /* visual styling */
                text-decoration: none;
                padding: 10px;
                font-family: sans-serif;
                color: #fff;
                background: #000;
                border-radius: 100px;
                border: 1px solid crimson;
                white-space: nowrap;
            }

            @media only screen and (max-width: 600px) {

                #headline {
                padding-top: 40px;
                }
                #tagline {
                    margin-top: 55px;
                }

                .col-text {
                padding-left: 10%;
                padding-right: 10%;
                }
            
            }
            #bottom-spacer {
                height: 100px;
            }


            .footer_svg {
                position: relative;
                bottom: 0;
                left: 0;
                width: 100%;
                overflow: hidden;
                line-height: 0;
                
            }

            .footer_svg svg {
                position: relative;
                display: block;
                width: calc(237% + 1.3px);
                height: 120px;
            }

            .footer_svg .shape-fill {
                fill: #022322;
            }

        `}</style>
    </>     
  )
}
