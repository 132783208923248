import React from 'react'
import aboutmevid from '../vid/aboutmevid.mp4';

export default function AboutMe() {
  const [pageLoaded, setPageLoaded] = React.useState(false);
  React.useEffect(() => {
    setPageLoaded(true);
  }, [])

  return (
    <>
      <video className='h-screen w-full pb-96 fixed -translate-y-10 self-stretch' autoPlay loop muted>
        <source src={aboutmevid} type='video/mp4' />
      </video>
        <div className={"bg-black grid justify-items-center mx-auto pt-12 opacity-70 ease-in duration-200 " + 
          (pageLoaded === true ? 
            'translate-x-0' :
            '-translate-x-12')
            }>

            <div className='mb-4'>
                <img src='https://avatars.githubusercontent.com/u/34861777?v=4' className=' border-green-400 border-2 rounded-full lg:-translate-x-56' alt='Myself standing in the snowy mountains'></img>
            </div>

            <div className='text-left mb-8 lg:w-1/2  text-white overflow-scroll'>
                <h1 className='text-4xl font-semibold p-3'>My programming history</h1>
                <p className='mb-3 p-3'>
                    I started my programming journey in 2018 with romantic ideas inspired by the
                    crypto boom. I spent my early years of this journey studying computer science, mathematics, game theory,
                    cryptography and web development. My primary languages were javascript, c++ and solidity and I dabbled
                    in python and java as well.
                </p>
                <p className='mb-3 p-3'>
                    In 2021 I started to do freelance web development. I was doing a lot of custom work for wordpress
                    sites
                    and so for the next couple years I wrote a lot of php and javascript.
                </p>
                <p className='mb-3 p-3'>
                    In 2023 I decided to take a step back from the web and learned the rust programming language.
                    A few months after I put out my first rust code in the wild, deploying a stripe payment processor.
                    Fast forward to 2025 I am currently building a suite of cross platform tools for business, commerce,
                    data analytics and an experimental new query language in rust.
                </p>
                <p className='mb-3 p-3'>
                    Ambition drives my process and I use the best tools for the job.
                    I put together my past experiences with some developer wizardry to create new innovative solutions
                    that are
                    performant, productive, scalable and most importantly secure.
                </p>
                <p className='mb-3 p-3'>
                    I come from a construction background and I translate a lot of that experience into my software
                    craft with a hands on and team oriented approach. I am always looking for new
                    challenges and opportunities to learn. Im interested in general software, crypto, blockchain, video games and robotics.
                </p>
                <p className='mb-3 p-3'>
                    Shoot me a message <a href="mailto:bill@crowsec.software">email</a>
                </p>
            </div>
        </div>
    </>
  )
}
