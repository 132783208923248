import React from 'react';
import { useLocation } from 'react-router-dom';

export default function ProjectSingle() {

    const location = useLocation()
    const { name, image, demo, repository, description } = location.state

    return ( 
        <div className='h-screen bg-slate-900 grid place-items-center justify-items-center text-white'>
            <div id='' className='grid md:grid-cols-6'>

                <div className='md:col-start-2 md:col-span-2 p-3'>
                    <img src={image} alt='' className='h-96 w-auto'/>
                </div>

                <div className='md:col-start-4 md:col-span-2 p-3 text-left '> 
                    <h2 className='text-4xl text-center mb-3 pt-32 pb-12'>{name}</h2>            
                    <div className='flex justify-center md:justify-start'><p id=''>Live Link </p><a className='pl-2' href={demo}>{demo?demo: 'No link available.'}</a> </div>              
                    {/* <div className='flex justify-center md:justify-start'><p id=''>Repo </p> <a className='pl-2' href={repository}>{repository? repository: 'No repository'}</a></div>   */}
                    <p className='mt-3'>{description ? description : "No description available"}</p>                     
                </div>             
            </div>
        </div>
    )
}

