import React from 'react'
import { Link } from 'react-router-dom'

export default function Resume() {
  
  return (
    <div className='bg-slate-900 grid md:grid-cols-10 pt-16 text-gray-50 pb-12 font-sans'>

        <div className='flex-wrap justify-center md:col-start-1 md:col-span-2'>
          <h1 className='text-4xl font-semibold p-1 pl-3 md:pl-8 mb-4 text-left'>Billy Best</h1>
          <div className='p-1 pl-3 md:pl-8 text-left'><p>+1 604 700 7524</p></div>
          <div className='p-1 pl-3 md:pl-8 text-left'><p><a href='mailto:bill@maasdao.io'>bill@crowsec.software</a></p></div>
          <div className='p-1 pl-3 md:pl-8 text-left'><p><a href='https://github.com/iFrogHop2Worlds'>github.com/iFrogHop2Worlds</a></p></div>

          <div className='mb-2 mt-3 p-3 md:p-8 text-left'>
            <p>
              I am a fullstack developer with experience in native and the web. I am currently working on software querying, performing transformations
              on billions of rows of data with visual outputs using a new query language we are developing.
            </p>
          </div>

          {/*<div className='mt-32 p-3 hidden md:inline-block'>*/}
          {/*  <h2 className='text-white text-2xl'>Certifications</h2>*/}
          {/*  <p>M220JS: MongoDB for JavaScript Developers</p>*/}
          {/*  <p className='text-md font-light'><a href='https://university.mongodb.com/course_completion/3ffe1524-40e9-4fc9-90b0-82d0e0b2d99a?*/}
          {/*  utm_source=copy&utm_medium=social&utm_campaign=university_social_sharing'>certificate link</a></p>*/}

          {/*  <p>M121: MongoDB Aggregation Framework</p>*/}
          {/*  <p className='text-md font-light'><a href='https://university.mongodb.com/course_completion/850b51a0-5bc3-4eb4-912b-ea6e39433cdb?*/}
          {/*  utm_source=copy&utm_medium=social&utm_campaign=university_social_sharing'>certificate link</a></p>*/}

          {/*  <p>M001: MongoDB Basics</p>*/}
          {/*  <p className='text-md font-light'><a href='https://university.mongodb.com/course_completion/aa16fe03-3da8-4e0f-a60e-6971a84ef0d2?*/}
          {/*  utm_source=copy&utm_medium=social&utm_campaign=university_social_sharing'>certificate link</a></p>*/}

          {/*  <p>JavaScript Data Structures and Algorithms</p>*/}
          {/*  <p className='text-md font-light'><a href='https://freecodecamp.org/certification/illbill/javascript-algorithms-and-data-structures'>certificate link</a></p>*/}
          {/*  */}
          {/*  <p>Portswigger/Burpsuite cert coming soon</p>*/}
          {/*</div>*/}
        </div>

        <div className='md:col-start-3 md:col-span-6 p-3 text-left'>
            <h3 className='bg-emerald-400 text-xl text-center font-semibold m-1 mt-6 mb-2 pt-2 pb-2 shadow-md shadow-black text-black'>Work
                Experience</h3>
            <div className='flex mt-2 mb-3'>
                <p className='font-thin text-cyan-500 pr-2 underline'>Freelance web development</p>
                <i>January 2021 to Present</i>
            </div>
            <p className='text-left'>
                I have collaborated with small to mid-sized companies to build dozens of e-commerce websites from the
                ground up and enhance
                functionality for existing platforms. My experience spans both front-end and back-end development, where
                I have:
                <ul className="list-none pl-0 mt-2 mb-2">
                    <li className="before:content-['\2192'] before:pr-2">Designed and implemented custom themes and user flows</li>
                    <li className="before:content-['\2192'] before:pr-2">Developed robust user authentication systems</li>
                    <li className="before:content-['\2192'] before:pr-2">Created backend dashboards for CMS, analytics, and tools</li>
                    <li className="before:content-['\2192'] before:pr-2">Integrated payment processing solutions, including Stripe and PayPal</li>
                    <li className="before:content-['\2192'] before:pr-2">Processed and formatted data for CSV file output</li>
                    <li className="before:content-['\2192'] before:pr-2">DB design, setup.</li>
                    <li className="before:content-['\2192'] before:pr-2">Updated legacy codebases (react/webpack)</li>
                </ul>

                My work adheres to best practices, prioritizing availability, search engine optimization (SEO), and
                performance optimization.
                I have worked extensively with a range of technologies, including PHP/WordPress, JavaScript
                (React/Next.js, Node.js), and Rust.

            </p>

            <div className='flex mt-2 mb-3'>
                <p className='font-thin text-cyan-500 pr-2 underline'>Web technology consultant</p>
                <i>January 2023</i>
            </div>
            <p className='text-left'>
                Leveraging my technical expertise, I collaborated with a dispensary to identify and address their unique goals and challenges.
                Through a comprehensive discovery process, the client and I worked together to assess their needs, which informed a detailed
                technical analysis of their digital infrastructure.

                I proposed a range of tailored solutions, each evaluated based on cost, timeline, and the extent of problem coverage. Additionally,
                I developed a flexible development roadmap that allowed for adjustments based on the trade-offs the client was willing to consider.

                This structured approach provided the client with a clear and actionable path to scale their operations and streamline key processes
                efficiently.
            </p>

            <h3 className='bg-emerald-400 text-xl text-center font-semibold m-1 mt-6 mb-2 pt-2 pb-2 shadow-md shadow-black text-black'>Personal
                Projects</h3>
            <div className='flex mt-2 mb-3'>
                <p className='font-thin text-cyan-500 pr-2 underline'>Charter csv</p>
                <i>current</i>
            </div>
            <p className='text-left'>
                Is a cross-platform program allowing users to quickly navigate through their csv files
                and visualize the data. It is written in rust and everything is built from scratch including a custom
                query language I developed which aims to make queries more intuitive and easier to write for
                non programmers. Soon will take advantage of ai aiding the query building process and a lot more.
                This program is meant to be easy to use but offers powerful features for productivity.
            </p>

            <hr className='mt-2 pb-2'/>
            <div className='flex mt-2 mb-3'>
                <p className='font-thin text-cyan-500 pr-2 underline'>Flock Inventory</p>
                <i>current</i>
            </div>
            <p className='text-left'>
                This saas product is a multi-tiered user system enabling master admins to deploy store instances,
                store admins to manage them (including sessions and employees), and all users to participate
                in inventory via barcode scanning. A React frontend prototype is complete, and a fast, reliable
                Rust backend is under development with a demo coming soon.
            </p>
            <hr className='mt-2 pb-2'/>
            <div className='flex mt-2 mb-3'>
                <p className='font-thin text-cyan-500 pr-2 underline'>Crows Booking</p>
                <i>current</i>
            </div>
            <p className='text-left'>
                saas / dev tool. You can use the api or our app to create services, set service providers and allow
                clients to book your services.
                This project was written in rust and uses postresql as the database.
            </p>
            <hr className='mt-2 pb-2'/>
            <div className='flex mt-2 mb-3'>
                <p className='font-thin text-cyan-500 pr-2 underline'>Crow Cache</p>
                <i>current</i>
            </div>
            <p className='text-left'>
                This was an experiment to see what it would take to make a cache service similar to redis. I built a
                multithreaded service in Rust
                which allows you to create and interact with your cache via the command line.
            </p>

            <hr className='mt-2 pb-2'/>


            <h3 className='bg-emerald-400 text-xl text-center font-semibold m-1 mt-6 mb-2 pt-2 pb-2 shadow-md shadow-black text-black'>Education</h3>
            <p className='font-thin text-white underline mb-2'>Self taught in Computer Science, Software Engineering</p>
            <p className='text-left'>
                I have independently studied core disciplines in computer science, including data structures and
                algorithms, computer networking, operating systems, compilers, cybersecurity, as well as mathematics,
                and game theory.
            </p>
            <ul className="list-none pl-0 mt-2 mb-2">
                My learning journey has been guided by a variety of reputable resources, including but not limited to
                <li className="before:content-['\2192'] before:pr-2">Grokking's Data Structures and Algorithms</li>
                <li className="before:content-['\2192'] before:pr-2">Computer Networking: A Top-Down Approach</li>
                <li className="before:content-['\2192'] before:pr-2">Compilers: Principles, Techniques, and Tools
                </li>
                <li className="before:content-['\2192'] before:pr-2">Hands-On Machine Learning with Scikit-Learn &
                    TensorFlow
                </li>
                <li className="before:content-['\2192'] before:pr-2">Rust Programming series (Rust, Async-Rust, Rust
                    Atomics and Locks)
                </li>
            </ul>
            <p className='text-left'>
                Additionally, I have engaged deeply with the MIT 6.006 Introduction to Algorithms lectures, and have
                dedicated thousands of hours to exploring documentation for diverse programming languages, libraries,
                and frameworks. My self-directed learning underscores my passion for mastering software engineering
                and problem-solving through code.
            </p>

            <h3 className='bg-emerald-400 text-xl text-center font-semibold m-1 mt-6 mb-2 pt-2 pb-2 shadow-md shadow-black text-black'>Additional
                Information</h3>
            <p className='text-left'>
                Coming from a construction background, I bring a distinctive perspective that sets me apart from the average developer.
                I thrive on tackling challenges, continually learning, and building innovative solutions that provide real value to people.

                My journey includes completing the Hacktoberfest challenge in 2020 and participating in two cryptocurrency hackathons in 2021,
                contributing to projects for Chainlink and Avalanche.

                Beyond coding, I have a variety of hobbies and interests, including hiking, camping, fishing, and skateboarding. These
                activities not only bring balance to my life but also inspire creativity and problem-solving in my professional endeavors.
            </p>
        </div>


        <div className='md:col-start-9 md:col-span-2 text-md p-3'>
            <h6 className='text-xl text-white mb-1 underline'>Main tools</h6>
            <p className='text-orange-300'>Rust</p>
            <p className='text-white'>C++</p>
            <p className='text-green-400'>Python</p>
            <p className='text-purple-800'>Solidity</p>
            <p className='text-yellow-400'>Javascript</p>
            <p className='text-blue-100'>TypeScript</p>
            <p className='text-orange-200'>PHP</p>
            <p className='text-green-200'>MongoDB</p>
            <p className='text-sky-200'>PostgreSQL</p>
            <p className='text-blue-200'>html</p>
            <p className='text-purple-200'>css</p>


            <h6 className='mt-4 text-xl text-white mb-1 underline'>Learning</h6>
            <p className='text-gray-100'>Graphics/Games</p>
            <p className='text-gray-100'>Game design</p>
            <p className='text-gray-100'>Ethical hacking</p>


            <div className='p-3 '>
                <h2 className='mt-8 text-white text-2xl'>Certifications</h2>
                <p>M220JS: MongoDB for JavaScript Developers</p>
                <p className='text-md font-light'><a href='https://university.mongodb.com/course_completion/3ffe1524-40e9-4fc9-90b0-82d0e0b2d99a?
              utm_source=copy&utm_medium=social&utm_campaign=university_social_sharing'>certificate link</a></p>

                <p>M121: MongoDB Aggregation Framework</p>
                <p className='text-md font-light'><a href='https://university.mongodb.com/course_completion/850b51a0-5bc3-4eb4-912b-ea6e39433cdb?
              utm_source=copy&utm_medium=social&utm_campaign=university_social_sharing'>certificate link</a></p>

                <p>M001: MongoDB Basics</p>
                <p className='text-md font-light'><a href='https://university.mongodb.com/course_completion/aa16fe03-3da8-4e0f-a60e-6971a84ef0d2?
              utm_source=copy&utm_medium=social&utm_campaign=university_social_sharing'>certificate link</a></p>

                <p>JavaScript Data Structures and Algorithms</p>
                <p className='text-md font-light'><a
                    href='https://freecodecamp.org/certification/illbill/javascript-algorithms-and-data-structures'>certificate
                    link</a></p>

                <p>Portswigger/Burpsuite cert coming soon</p>
            </div>
        </div>
    </div>
  )
}
