import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function Contact() {

  const[email, setEmail] = useState("");
  const[name, setName] = useState("");
  const[question, setQuestion] = useState("");
  const[formStatus, setFormStatus] = useState("Submit");
  const handleFormInput = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const names = target.name;
    if(names === 'name'){
      setName(value);
    } else if (names === "email"){
      setEmail(value);
    } else if (names === "question"){
      setQuestion(value);
    }
  }

  // create a post request submitting form fields
  // later we will create a component for admin panel
  // where we can select question and add them to a new array
  // which our faq component will draw from.
  const _submitQuestion = async (e) => {
    e.preventDefault();
    setFormStatus("Submitting..");
    axios.post(`http://138.197.151.61:7500/user-api/submit-question`, { 
      email: email,
      name: name,
      question: question
    })
    .then( res => {
      console.log("the ressy res:  " + res);
      setFormStatus("Submit");
    })
    .catch( error => console.log("errpr on handle submmit: " + error));

    setEmail("");
    setName("");
    setQuestion("");
  }

  return (
    <div className='h-screen bg-slate-950 justify-items-center'>  
    <div className='grid translate-y-32'>
        
        <form className='p-4 pb-4 lg:w-1/3 mx-auto'>
          <div className='flex justify-start p-1 m-1'>
            <label className='pr-3  text-white' for="name"><b>name </b> </label>
            <input className='w-full mr-auto' type="text" id="form-field" name="name" size="10" maxlength="42" value={name} onChange={handleFormInput}/>
          </div>
          <div className='flex justify-start p-1 m-1'>
            <label className='pr-3  text-white' for="email"  ><b>email </b></label><br/>
            <input className='w-full mr-auto' type="text" id="form-field" name="email" size="10" maxlength="42" value={email} onChange={handleFormInput} required/>
          </div>
          <div className='grid pt-12'>
            <label className='pb-1 text-left text-white' for="question"><b>question</b></label><br/>
            <textarea className='w-full' rows="6" cols="56" id="question" name="question" required maxlength="475" value={question} onChange={handleFormInput}></textarea>
          </div>
           <br/>
           <button className='p-3 border-2 w-3/12 mt-2 text-white' type="submit" value="Submit" onClick={_submitQuestion}>{formStatus}</button>
        </form> 

        <p className=''>Your questions may be displayed in a FAQ section.</p>
      </div>

    </div>
    
  )
}
